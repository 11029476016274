<template>
     <!-- Start Small Features Area -->
    <section class="small-features">
        <div class="container">
            <div class="inner-content">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12 pt-3" v-for="(obj, key) in featureJSON" :key="key">
                        <!-- Start Single Feature -->
                        <div class="single-feature">
                           <i :class="obj.icon"></i>
                            <h2>{{ obj.header }}
                                <span>{{ obj.caption }}</span>
                            </h2>
                        </div>
                        <!-- End Single Feature -->
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <!-- End Small Features Area -->

</template>
<script>
export default {
  data: () =>({
    featureJSON: [
      {
        icon: 'lni lni-investment',
        header: 'OUR MISSION',
        caption: 'Exceljuta provides clients with the highest quality of services in software, Engineering, digital marketing, staffing, property management and technological consulting. Our company is built with individuals who are passionate, purpose-led and who believe in developing true partnerships.'
      },
      {
        icon: 'lni lni-pie-chart',
        header: 'OUR VISION STATEMENT',
        caption: 'Our Company was founded with a vision to provide value- added solutions to our clients. We believe in earning the trust of our clients by giving maximum benefits from their investments. We undertake challenging projects with innovative methodologies.'
      },
      {
        icon: 'lni lni-handshake',
        header: 'WHAT WE DO',
        caption: 'Our dedicated teams of Recruitment, IT, Engineering, digital marketing and consulting, work with passion. They innovate and brainstorm for ideas to excel in the projects they undertake. We guarantee  client  satisfaction in all our works.'
      }
    ]

  })
    
}
</script>
<style >
/*======================================
    Small Features CSS
========================================*/
section.small-features {
    padding-bottom: 50px;
}

.small-features .inner-content {
  display: inline-block;
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: -5%;
  z-index: 5;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .inner-content {
    margin-top: -80px;
  }
}

@media (max-width: 767px) {
  .small-features .inner-content {
    margin-top: 0;
  }
}

.small-features .single-feature {
  position: relative;
  padding: 50px 43px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.048);
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.048);
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.small-features .single-feature:hover {
  -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.048);
          box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.048);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.small-features .single-feature i {
  color: #72287a;
  font-size: 35px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.small-features .single-feature h2 {
  font-size: 16px;
  font-weight: 600;
  color: #081828;
}

.small-features .single-feature h2 span {
  font-size: 14px;
    line-height: 25px;
    color: #888;
    text-align: justify;
    display: block;
    margin-top: 8px;
    font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .single-feature {
    padding: 40px 30px;
    text-align: center;
  }
  .small-features .single-feature i {
    position: relative;
    left: 0;
    top: 0;
    display: inline-block;
    margin-bottom: 15px;
    -webkit-transform: none;
            transform: none;
  }
  .small-features .single-feature h2 {
    font-size: 12px;
  }
}
</style>