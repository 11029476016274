<template>
 <nav class="navbar navbar-expand-lg navbar-dark primary">
        <div class="container-fluid">
            <a class="navbar-brand" href="../"><img src="../assets/images/logo/Exceljuta-logo.png" class="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                    <span class="navbar-toggler-icon"></span>
                </button>
                        <div class="collapse navbar-collapse" id="main_nav">
                                 <ul class="navbar-nav mx-auto">
                                        <li class="nav-item"><a class="nav-link" href="../about">ABOUT US</a></li>
                                    <li class="nav-item dropdown has-megamenu">
                                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">SERVICES</a>
                                        <div class="dropdown-menu megamenu" role="menu">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            
                                                            <div class="col-md-5th-1 col-sm-4 col-md-offset-0 col-sm-offset-2  pt-3 submenu">
                                                                   <li class="mega-menu-title sub-menu"><h6>Software Development</h6></li>
                                                                   <li><a class="nav-link" href="../application-development">Application Development </a></li>
                                                                    <li><a class="nav-link" href="../software-testing">Software Testing </a></li>
                                                                    <li><a class="nav-link" href="../cloud-computing">Cloud Computing </a></li>
                                                                    <li><a class="nav-link" href="../data-analytics">Data Analytics </a></li>
                                                                  
                                                            </div>
                                                            <div class="col-md-5th-1 col-sm-4 pt-3  submenu">
                                                                 <li class="mega-menu-title sub-menu"><h6>Engineering Services</h6></li>
                                                                   <li><a class="nav-link" href="../embedded-engineering">Embedded Engineering </a></li>
                                                                    <li><a class="nav-link" href="../software-engineering">Software Engineering </a></li>
                                                                    <li><a class="nav-link" href="../digital-engineering">Digital Engineering </a></li>
                                                                    <li><a class="nav-link" href="../mechanical-engineering">Mechanical Engineering </a></li>
                                                                    <li><a class="nav-link" href="../system-&-hardware-engineering">System and Hardware Engineering </a></li>
                                                            </div>
                                                            <div class="col-md-5th-1 col-sm-4 pt-3  submenu">
                                                                <li class="mega-menu-title sub-menu"><h6>Digital Marketing</h6></li>
                                                                   <li><a class="nav-link" href="../social-media-marketing">Social Media Marketing </a></li>
                                                                    <li><a class="nav-link" href="../seo-services">Seo Services </a></li>
                                                                    <li><a class="nav-link" href="../content-marketing">Content Marketing </a></li>
                                                                    <li><a class="nav-link" href="../ppc-marketing">PPC Marketing </a></li>
                                                                    <li><a class="nav-link" href="../email-marketing">Email Marketing </a></li>
                                                            </div>
                                                            <div class="col-md-5th-1 col-sm-4 pt-3 submenu">
                                                                 <li class="mega-menu-title sub-menu"><h6>Talent Solutions</h6></li>
                                                                   <li><a class="nav-link" href="../contract-staffing">Contract Staffing </a></li>
                                                                    <li><a class="nav-link" href="../permanent-staffing">Permanent Staffing </a></li>
                                                                    <li><a class="nav-link" href="../onsite-recruitment">Onsite Recruitment </a></li>
                                                                    <li><a class="nav-link" href="../campus-recruitment">Campus Recruitment </a></li>
                                                                    <li><a class="nav-link" href="../consulting-services">CONSULTING SERVICES </a></li>
                                                             </div>
                                                            <div class="col-md-5th-1 col-sm-4 pt-3  submenu">
                                                                    <li class="mega-menu-title sub-menu"><h6>Asset Management Services</h6></li>
                                                                   <li><a class="nav-link" href="../property-management">Property Management </a></li>
                                                                    <li><a class="nav-link" href="../parking-management">Parking Management </a></li>
                                                                    <li><a class="nav-link" href="../property-maintenance">Property Maintenance </a></li>
                                                             </div>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                        </div> <!-- dropdown-mega-menu.// -->
                                    </li>
                                    <li class="nav-item"><a class="nav-link" href="../product">PRODUCT </a></li>
                                        <li class="nav-item"><a class="nav-link" href="../contact">CONTACT US </a></li>
                                    </ul>
                        </div> <!-- navbar-collapse.// -->
                        
                    </div> <!-- container-fluid.// -->
    </nav>  
</template>
<script>
  export default {
    
  }
  </script>
<style >
.navbar-dark .navbar-toggler-icon {
    background-image: url('../assets/images/logo/toogle-button.svg') !important;
}
.dropdown-toggle::after { margin: 0px 0px 0px 10px;
}
a.nav-link > a:hover {
    color: #ed1d8d;
}
.navbar .navbar-nav .nav-link {
    color: #72287a;
    font-size: 14px;
    font-weight: 500;
}
a.nav-link.dropdown-toggle.show::after {
    top: 18px;right: 0;
    -webkit-transform: rotate( 180deg);
    transform: rotate(180deg);
}
.dropdown-menu[data-bs-popper] {
    top: 95%;
}
.navbar-dark .navbar-nav .nav-link:focus{
    color: #72287a !important;
}
@media (min-width: 992px)
{
.navbar .megamenu {
    left: 10px !important;
    width: 98% !important;
}
}
.navbar .navbar-nav .nav-link:hover {
    color: #ed1d8d;
}
.sm-icons {
  flex-direction: row;
}
@media only screen and (max-width: 960px) {
  .sm-icons .nav-item {
    padding-right: 1em;
  }
  
}
  .navbar .megamenu{ padding: 1rem; }

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }
 
}	
 .navbar-expand-lg .navbar-nav .dropdown-menu {border-top-color: #ed1d8d;}
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 451px){
div#main_nav { width: 96% !important;
}
}
.submenu > li > a.nav-link > a {
    font-size: 14px;
    padding: 0px !important;
}
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
  .dropdown-toggle::after {
   margin: 5px 0px;
    position: absolute;right: 0px;
}

div#main_nav {
    position: absolute;
    top: 100px !important;left: 0;width: 97%;margin: 10px;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 15px 20px 0px rgb(0 0 0 / 10%);
    padding: 10px 20px;
    max-height: 550px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
}
}
img.logo { width: 100px;margin: 0px 50px;
}

nav.navbar.navbar-expand-lg.navbar-dark.primary{
position: fixed;
    z-index: 99;
    -webkit-box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 5%);
    background: #fff;
    padding: 0px 0;width:100%;
}
li.nav-item > a {
    padding: 10px;
    text-decoration: none;
}
.col-xs-5th-1, .col-xs-5th-2, .col-xs-5th-3, .col-xs-5th-4{
  float: left;
}
.col-xs-5th-5{
  float: left;
  width: 100%;
}
.col-xs-5th-4{
  width: 80%;
}
.col-xs-5th-3{
  width: 60%
}
.col-xs-5th-2{
  width: 40%
}
.col-xs-5th-1{
  width: 20%
}
.col-xs-5th-pull-5{
  right: 100%
}
.col-xs-5th-pull-4{
  right: 80%
}
.col-xs-5th-pull-3{
  right: 60%
}
.col-xs-5th-pull-2{
  right: 40%
}
.col-xs-5th-pull-1{
  right: 20%
}
.col-xs-5th-pull-0{
  right: auto
}
.col-xs-5th-push-5{
  left: 100%
}
.col-xs-5th-push-4{
  left: 80%
}
.col-xs-5th-push-3{
  left: 60%
}
.col-xs-5th-push-2{
  left: 40%
}
.col-xs-5th-push-1{
  left: 20%
}
.col-xs-5th-push-0{
  left: auto
}
.col-xs-5th-offset-5{
  margin-left: 100%
}
.col-xs-5th-offset-4{
  margin-left: 80%
}
.col-xs-5th-offset-3{
  margin-left: 60%
}
.col-xs-5th-offset-2{
  margin-left: 40%
}
.col-xs-5th-offset-1{
  margin-left: 20%
}
.col-xs-5th-offset-0{
  margin-left: 0%
}
@media (min-width: 768px){
  .col-sm-5th-1, .col-sm-5th-2, .col-sm-5th-3, .col-sm-5th-4{ float: left
  }
  .col-sm-5th-5{ float: left;width: 100%;
  }
  .col-sm-5th-4{ width: 80%}
  .col-sm-5th-3{ width: 60%}
  .col-sm-5th-2{ width: 40%}
  .col-sm-5th-1{ width: 20%}
  .col-sm-5th-pull-5{right: 100%}
  .col-sm-5th-pull-4{ right: 80%}
  .col-sm-5th-pull-3{right: 60%}
  .col-sm-5th-pull-2{right: 40%}
  .col-sm-5th-pull-1{ right: 20%}
  .col-sm-5th-pull-0{ right: auto}
  .col-sm-5th-push-5{ left: 100%}
  .col-sm-5th-push-4{ left: 80%}
  .col-sm-5th-push-3{ left: 60%}
  .col-sm-5th-push-2{ left: 40%}
  .col-sm-5th-push-1{ left: 20%}
  .col-sm-5th-push-0{ left: auto}
  .col-sm-5th-offset-5{ margin-left: 100%}
  .col-sm-5th-offset-4{ margin-left: 80%}
  .col-sm-5th-offset-3{ margin-left: 60%}
  .col-sm-5th-offset-2{ margin-left: 40%}
  .col-sm-5th-offset-1{ margin-left: 20%}
  .col-sm-5th-offset-0{ margin-left: 0%}
}
@media (min-width: 992px){
  .col-md-5th-1, .col-md-5th-2, .col-md-5th-3, .col-md-5th-4{ float: left;
  background: #fff;
    -webkit-box-shadow: 0px 5px 20px #0000001a;
    box-shadow: 0px 5px 20px #0000001a;
  }
  .col-md-5th-5{ float: left;width: 100%}
  .col-md-5th-4{ width: 80%}
  .col-md-5th-3{ width: 60%}
  .col-md-5th-2{ width: 40%}
  .col-md-5th-1{ width: 20%}
  .col-md-5th-pull-5{ right: 100%}
  .col-md-5th-pull-4{ right: 80%}
  .col-md-5th-pull-3{ right: 60%}
  .col-md-5th-pull-2{ right: 40%}
  .col-md-5th-pull-1{ right: 20%}
  .col-md-5th-pull-0{ right: auto}
  .col-md-5th-push-5{ left: 100%}
  .col-md-5th-push-4{ left: 80%}
  .col-md-5th-push-3{ left: 60%}
  .col-md-5th-push-2{ left: 40%}
  .col-md-5th-push-1{ left: 20%}
  .col-md-5th-push-0{ left: auto}
  .col-md-5th-offset-5{ margin-left: 100%}
  .col-md-5th-offset-4{ margin-left: 80%}
  .col-md-5th-offset-3{ margin-left: 60%}
  .col-md-5th-offset-2{ margin-left: 40%}
  .col-md-5th-offset-1{ margin-left: 20%}
  .col-md-5th-offset-0{ margin-left: 0%}
}
@media (min-width: 1200px){
  .col-lg-5th-1, .col-lg-5th-2, .col-lg-5th-3, .col-lg-5th-4{ float: left}
  .col-lg-5th-5{ float: left;width: 100%}
  .col-lg-5th-4{ width: 80%}
  .col-lg-5th-3{ width: 60%}
  .col-lg-5th-2{ width: 40%}
  .col-lg-5th-1{ width: 20%}
  .col-lg-5th-pull-5{ right: 100%}
  .col-lg-5th-pull-4{ right: 80%}
  .col-lg-5th-pull-3{ right: 60%}
  .col-lg-5th-pull-2{ right: 40%}
  .col-lg-5th-pull-1{ right: 20%}
  .col-lg-5th-pull-0{ right: auto}
  .col-lg-5th-push-5{ left: 100%}
  .col-lg-5th-push-4{ left: 80%}
  .col-lg-5th-push-3{ left: 60%}
  .col-lg-5th-push-2{ left: 40%}
  .col-lg-5th-push-1{ left: 20%}
  .col-lg-5th-push-0{ left: auto}
  .col-lg-5th-offset-5{ margin-left: 100%}
  .col-lg-5th-offset-4{ margin-left: 80%}
  .col-lg-5th-offset-3{ margin-left: 60%}
  .col-lg-5th-offset-2{ margin-left: 40%}
  .col-lg-5th-offset-1{ margin-left: 20%}
  .col-lg-5th-offset-0{ margin-left: 0%}
}

li.nav-item {
    padding: 10px 15px;
}
  </style>