<template>
   <!-- Start Hero Area -->
    <section class="hero-area overlay">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="hero-content">
                        <h5 class="wow fadeInUp" >Welcome to Exceljuta</h5>
                        <h1 class="wow fadeInUp" >‘To Excel in million ways’<span>is what Exceljuta means.</span>
                        </h1>
                        <p class="wow fadeInUp" >With a wave of digital transformation going on each second Exceljuta delivers technological services with the latest updated Agile values and AI approach towards technology. Our expert dedicated teams work with passion from ideation to execution enabling global companies move up the Value Chain.</p>
                        <div class="button wow fadeInUp">
                            <a href="../About" class="btn btn-alt">Know More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Hero Area -->
</template>

<script>

export default {}
</script>
<style >
/*======================================
    Hero Area CSS
========================================*/
.hero-area {
  position: relative;
  padding: 200px 0 100px 0;
  background-image: url("../assets/images/background/Background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 100px 0;
  }
}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 70px 0;
  }
}


.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  padding: 0px 100px;
}

.hero-area .hero-content h5 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
}

.hero-area .hero-content h1 {
  font-size: 48px;
  font-weight: 800;
  line-height: 50px;
  color: #fff;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  font-size: 15px;
  color: #fff;
}

.hero-area .hero-content .button {
  margin-top: 40px;
}


.hero-area .hero-content .button .btn.btn-alt {
  background-color: #ffffff6b;
  color: #fff;
}

.hero-area .hero-content .button .btn.btn-alt:hover {
  background-color: #fff !important;
  color: #723FE4 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }
  .hero-area .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
  }
  .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
  }
  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }
  
  .hero-area .hero-content .button .btn:last-child {
    margin: 0;
  }
}

</style>
