<template>
    <!-- Start Features Area -->
    <section class="features section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">Why choose us</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" v-for="(obj, key) in featureareaJSON" :key="key">
                    <!-- Start Single Feature -->
                    <div class="single-feature wow fadeInUp" data-wow-delay=".2s">
                         <i :class="obj.icon"></i>
                        <h3>{{ obj.header }}</h3>
                        <p>{{ obj.caption }}</p>
                    </div>
                    <!-- End Single Feature -->
                </div>
               
            </div>
        </div>
    </section>
    <!-- End Features Area -->
</template>
<script>
export default {
     data: () =>({
    featureareaJSON: [
      {
        icon: 'lni lni-investment',
        header: 'WE EARN TRUST-100% CUSTOMER REFERENCES ',
        caption: 'Each one of our client is a reference for Exceljuta and the work we do. We don’t cherry pick clients for references. We know that being a trusted partner is more than just a promise. We earn your trust every day.'
      },
      {
        icon: 'lni lni-lock',
        header: 'INNOVATIONS IN TECHNOLOGY AND METHODOLOGY',
        caption: 'Our innovative approach is rooted in all aspects of our company’s existence. Our experts identify opportunities to apply innovative solutions in technology. This results in a complete solution that’s tailored to meet your needs.'
      },
      {
        icon: 'lni lni-reload',
        header: 'FLEXIBLE AND AGILE',
        caption: 'Our clients are unique. They conduct their businesses in a world where objectives and priorities are constantly remade. Our approach to all of our projects is by being flexible and agile for our clients so that we can support them completely.'
      },
      {
        icon: 'lni lni-shield',
        header: 'READY TO UNDERTAKE CHALLENGES',
        caption: 'As a dynamic, young and innovative organization, with exceptional talent, we are not afraid to think outside the box or go all the way out to meet IT challenges. We are always eager for challenging projects.'
      },
      {
        icon: 'lni lni-cog',
        header: 'VALUE WITH A MEASUREABLE ROI',
        caption: 'The key to value of trust in our company is the pricing; we price our services so that businesses can get maximum benefit from their investment. We guarantee a measurable ROI (return on investment).'
      },
      {
        icon: 'lni lni-layers',
        header: 'OUR TALENT- EXCELJUTANS',
        caption: 'Exceljuta is known for the high calibre of our software professionals, Engineers, recruitment specialists, digital marketing team, property management team and technological consulting. They work with passion and dedication.'
      }
    ]

  })
}
</script>
<style>
/*======================================
    Features Area CSS
========================================*/
.features {
  background-color: #fff;
    padding: 60px 0px;
}
.features .section-title {
  margin-bottom: 30px;
}

.features .single-feature {
  text-align: left;
  padding: 34px;
  background-color: #f9f9f9;
  border-radius: 6px;
  position: relative;
  margin-top: 30px;
  border: 1px solid #eee;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.features .single-feature:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
          box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.features .single-feature i {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background-color: #72287a;
  color: #fff;
  font-size: 20px;
  border-radius: 7px;
  -webkit-box-shadow: 0px 4px 6px #0000002a;
          box-shadow: 0px 4px 6px #0000002a;
  margin-bottom: 30px;
}

.features .single-feature h3 {
  font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
  .features .section-title {
  margin:0px 30px;
}
}
.features .single-feature p {
      line-height: 25px;
    font-size: 16px;
    text-align: justify;
}

</style>