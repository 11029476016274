<template>
    <!-- Start About Area -->
    <section class="about-us section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-12">
                    <div class="content-left">
                        <img src="../assets/images/background/about-img.jpg" alt="#">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                    <!-- content-1 start -->
                    <div class="content-right">
                        <h2>Visitor Management Software</h2>
                        <p>Everything around us is being technologically- driven from the mobile devices we view every now and then to the ticket, coffee and money vending machines for our use at our own disposal. We present you the VMS software (Visitor Management Software) to take account of every visitor to your offices. This is normally the work of a receptionist. It is deemed a necessity for large office spaced companies; considering the fact that an unmanned receptionist job can come in handy along with a receptionist by delivering service 24/7 and in keeping the records up-to-date and also stressing the fact of being a technologically advanced company.</p>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul class="list">
                                    <li><i class="lni lni-checkmark-circle"></i>Benefits of using VMS</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Essential Features in VMS</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Importance of VMS</li>
                                </ul>
                            </div>
                        </div>
                        <div class="button">
                            <a href="/Product" class="btn read">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End About Area -->
</template>
<script>
export default {
    
}
</script>
<style >
/*======================================
    About Us CSS
========================================*/
.about-us {
  background-color: #f9f9f9;
    padding: 50px 0px;
}
a.btn.read {
    padding: 10px 15px;
    font-size: 12px;
}
.about-us .content-left {
  position: relative;
}

.about-us .content-left img {
  width: 100%;
  border-radius: 5px;
}

.about-us .content-right {
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-us .content-right {
    padding: 0px 30px;
    margin-top: 30px;
  }
}

.about-us .content-right h2 {
  display: block;
  font-size: 32px;
  margin-bottom: 25px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us .content-right h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .about-us .content-right h2 {
    font-size: 22px;
    line-height: 35px;
  }
}

.about-us .content-right h3 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 8px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
}

.about-us .content-right p {
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
}

.about-us .content-right .list {
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-us .content-right .list {
    margin-top: 10px;
  }
}

.about-us .content-right .list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  padding-left: 25px;
  position: relative;
}

.about-us .content-right .list li i {
  color: #723FE4;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.about-us .content-right .list li:last-child {
  margin: 0;
}

.about-us .content-right .button {
  margin-top: 15px;
}

</style>