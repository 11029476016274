<template>
    <!-- Start Call Action Area -->
    <section class="call-action">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-9 col-12">
                    <div class="text">
                        <h2 style="line-height: 42px;">Call us today at <span>044-4551 0181/ 82/ 83</span> or Email us at <span>info@exceljulta.com</span>
                        </h2>
                        <p style="margin-top: 15px;">We strive to provide our customers with top notch support to make their business experience wonderful.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-12">
                    <div class="button">
                        <a href="../Contact" class="btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Call Action Area -->
</template>
<script>
export default {
    
}
</script>
<style >

/*======================================
    Call To Action CSS
========================================*/
.call-action {
  padding: 40px 0;
    background-color: #f9f9f9;
}
.button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  padding: 13px 30px;
  background-color: #ed1d8d;
  color: #fff;
  border: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-right: 7px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

.button .btn i {
  display: inline-block;
  margin-right: 5px;
}

.button .btn:last-child {
  margin: 0;
}

.button .btn:hover {
  color: #fff;
  background-color: #72287a;
  -webkit-box-shadow: 0px 4px 4px #0000000f;
          box-shadow: 0px 4px 4px #0000000f;
}

.button .btn-alt {
  background-color: #081828 !important;
  color: #fff !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

.button .btn-alt:hover {
  background-color: #ed1d8d !important;
  color: #fff !important;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action {
    padding: 30px 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .call-action {
    padding: 30px;
    text-align: center;
  }
}

.call-action .text h2 {
  font-size: 23px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .text h2 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .call-action .text h2 {
    font-size: 18px;
  }
}

.call-action .text h2 span {
  color: #ed1d8d;
}

.call-action .button {
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .button {
    float: none;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .call-action .button {
    float: none;
    margin-top: 25px;
  }
}

</style>