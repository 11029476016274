import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/application-development',
    name: 'Services',
    component: () => import('../views/Application-Development.vue')
  },
  {
    path: '/software-testing',
    name: 'Software Testing',
    component: () => import('../views/Softwaretesting.vue')
  },
  {
    path: '/cloud-computing',
    name: 'Cloud Service',
    component: () => import('../views/Cloud.vue')
  },
  {
    path: '/data-analytics',
    name: 'Data Analytics',
    component: () => import('../views/Data.vue')
  },
  {
    path: '/embedded-engineering',
    name: 'Embedded Engineering',
    component: () => import('../views/Embedded.vue')
  },
  {
    path: '/software-engineering',
    name: 'Software Engineering',
    component: () => import('../views/Software.vue')
  },
  {
    path: '/digital-engineering',
    name: 'Digital Engineering',
    component: () => import('../views/Digital.vue')
  },
  {
    path: '/mechanical-engineering',
    name: 'Mechanical Engineering',
    component: () => import('../views/Mechanical.vue')
  },
  {
    path: '/software-engineering',
    name: 'Software Engineering',
    component: () => import('../views/Software.vue')
  },
  {
    path: '/system-&-hardware-engineering',
    name: 'System-&-Hardware Engineering',
    component: () => import('../views/System-&-Hardware.vue')
  },
  
  {
    path: '/social-media-marketing',
    name: 'Social Media Marketing',
    component: () => import('../views/Social-Media-Marketing.vue')
  },
  
  {
    path: '/seo-services',
    name: 'Seo Services',
    component: () => import('../views/Seo-Services.vue')
  },
  
  {
    path: '/content-marketing',
    name: 'Content Marketing',
    component: () => import('../views/Content-Marketing.vue')
  },
  
  {
    path: '/ppc-marketing',
    name: 'PPC Marketing',
    component: () => import('../views/PPC-Marketing.vue')
  },
  
  {
    path: '/email-marketing',
    name: 'Email Marketing',
    component: () => import('../views/Email-Marketing.vue')
  },
  
  {
    path: '/contract-staffing',
    name: 'Contract Staffing',
    component: () => import('../views/Contract-Staffing.vue')
  },
  
  {
    path: '/permanent-staffing',
    name: 'Permanent Staffing',
    component: () => import('../views/Permanent-Staffing.vue')
  },
  
  {
    path: '/onsite-recruitment',
    name: 'Onsite Recruitment',
    component: () => import('../views/Onsite-Recruitment.vue')
  },
  
  {
    path: '/campus-recruitment',
    name: 'Campus Recruitment',
    component: () => import('../views/Campus-Recruitment.vue')
  },
  
  {
    path: '/consulting-services',
    name: 'CONSULTING SERVICES',
    component: () => import('../views/Consulting-Services.vue')
  },
  
  {
    path: '/property-management',
    name: 'Property Management',
    component: () => import('../views/Property-Management.vue')
  },
  {
    path: '/parking-management',
    name: 'Parking Management',
    component: () => import('../views/Parking-Management.vue')
  },
  {
    path: '/property-maintenance',
    name: 'Property Maintenance',
    component: () => import('../views/Property-Maintenance.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/contact',
    name: 'contact us',
    component: () => import('../views/Contact.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
  
})

export default router
