<template>
     <!-- Start Footer Area -->
    <footer class="footer">
        <!-- Start Footer Top -->
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <!-- Single Widget -->
                        <div class="single-footer f-about">
                            <div class="logo">
                                <a href="../">
                                <h3 class="footertitle">Exceljuta Talent Solution Pvt, Ltd.,</h3>
                                </a>
                            </div>
                            <p>No. 30, Srinivasa Nagar 1st street, OFF OMR Road, Kandhanchavadi, Chennai - 600096.</p>
                            <ul class="social">
                                <li><a href="https://www.facebook.com/Exceljuta-1710330238985845/"><i class="lni lni-facebook-filled"></i></a></li>
                                <li><a href="https://twitter.com/exceljuta"><i class="lni lni-twitter-original"></i></a></li>
                                <li><a href="https://in.linkedin.com/company/exceljuta-talent-solution-pvt-ltd"><i class="lni lni-linkedin-original"></i></a></li>
                            </ul>
                            <p class="copyright-text">Copyrights © 2021 All Rights Reserved by<a href="https://exceljuta.com/"
                                    rel="nofollow" target="_blank"> Exceljuta</a>
                            </p>
                        </div>
                        <!-- End Single Widget -->
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                        <div class="row">
                           
                            <div class="col-lg-4 col-md-4 col-12">
                                <!-- Single Widget -->
                                <div class="single-footer f-link">
                                    <h3>SERVICES</h3>
                                    <ul>
                                        <li><a class="Footerservice">Software Development</a></li>
                                        <li><a class="Footerservice">Engineering Services</a></li>
                                        <li><a class="Footerservice">Digital Marketing</a></li>
                                        <li><a class="Footerservice">Talent Solutions</a></li>
                                        <li><a class="Footerservice">Asset Management Services</a></li>
                                    </ul>
                                </div>
                                <!-- End Single Widget -->
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <!-- Single Widget -->
                                <div class="single-footer f-link">
                                    <h3>COMPANY</h3>
                                    <ul>
                                        <li><a><router-link to="../About">About Us</router-link></a></li>
                                        <li><a><router-link to="../Product">Product</router-link></a></li>
                                        <li><a><router-link to="../Contact">Contact Us</router-link></a></li>
                                    </ul>
                                </div>
                                <!-- End Single Widget -->
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <!-- Single Widget -->
                                <div class="single-footer f-link">
                                    <h3>EMAIL US</h3>
                                    <ul>
                                        <li><a href="mailto:info@exceljuta.com">info@exceljuta.com</a></li>
                                     </ul>
                                    <h3>SPEAK TO US</h3>
                                    <ul>
                                        <li><a href="tel:04445510181">044-4551 0181/ 82/ 83</a></li>
                                        <li><a href="tel:+19253026900">+1 925 302 6900</a></li>
                                        <li><a href="tel:+13238703030">+1-323-870 3030</a></li>
                                    </ul>
                                </div>
                                <!-- End Single Widget -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Footer Top -->
    </footer>
    <!--/ End Footer Area -->
     <a href="#" class="scroll-top">
        <i class="lni lni-chevron-up"></i>
    </a>
</template>
<script>
export default {
   
}
</script>
<style>
/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #72287a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #fff !important;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 5px;
}

.scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: #ed1d8d;
}
/*======================================
	Footer CSS
========================================*/
.single-footer.f-link > ul {
    padding-left: 10px;
}
a {
    text-decoration: none;
}
.footer {
  background-color: #081828;
  position: relative;
}
a.Footerservice {
    cursor: pointer;
}
.footer .footer-top {
  padding: 50px 0px 20px 0px ;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-top {
    padding: 50px 0px 20px 0px;
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .footer-top {
    padding: 40px 0px 20px 0px;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer {
    margin-top: 40px;
    margin: 35px;
  }
}

.footer .single-footer.f-about {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about {
    padding: 0;
  }
}

.footer .single-footer.f-about .logo img {
  width: 180px;
}

.footer .single-footer.f-about p {
  color: #D2D6DC;
  margin-top: 20px;
  font-size: 14px;
}

.footer .single-footer.f-about .social {
  margin-top: 30px;
   padding-left: 0px;
}

.footer .single-footer.f-about .social li {
  display: inline-block;
  margin-right: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .social li a {
  color: #D2D6DC;
  font-size: 15px;
}

.footer .single-footer.f-about .social li a:hover {
  color: #ed1d8d;
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .copyright-text {
  color: #D2D6DC;
  font-size: 14px;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .copyright-text {
    margin-top: 20px;
  }
}

.footer .single-footer.f-about .copyright-text a {
  color: #D2D6DC;
}

.footer .single-footer.f-about .copyright-text a:hover {
  color: #ed1d8d;
}

.footer .single-footer.f-link li {
  display: block;
  margin-bottom: 12px;
}

.footer .single-footer.f-link li:last-child {
  margin: 0;
}

.footer .single-footer.f-link li a {
  font-size: 15px;
  font-weight: 400;
  color: #D2D6DC;
}

.footer .single-footer.f-link li a:hover {
  color: #ed1d8d;
}

.footer .single-footer h3 {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 25px;
  color: #D2D6DC;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

.footer .footer-newsletter {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter {
    padding-bottom: 60px;
    text-align: center;
  }
}

.footer .footer-newsletter .inner-content {
  border: 2px solid rgba(238, 238, 238, 0.171);
  padding: 50px;
  border-radius: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

.footer .footer-newsletter .title {
  position: relative;
}

.footer .footer-newsletter .title h3 {
  color: #D2D6DC;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

.footer .footer-newsletter .title p {
  font-size: 15px;
  color: #D2D6DC;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

.footer .footer-newsletter .title p a {
  color: #ed1d8d;
  text-decoration: underline;
}

.footer .footer-newsletter .title p a:hover {
  color: #fff;
}

.footer .footer-newsletter .newsletter-form {
  position: relative;
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
  }
}

.footer .footer-newsletter .newsletter-form input {
  height: 48px;
  width: 300px;
  display: inline-block;
  background: transparent;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 0 20px;
  color: #fff !important;
  font-size: 14px;
  background-color: #fff3;
  border-radius: 5px;
  margin-right: 148px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    margin: 0;
    padding-right: 144px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

.footer .footer-newsletter .newsletter-form ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.footer .footer-newsletter .newsletter-form .button {
  display: inline-block;
  position: absolute;
  right: 0;
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form .button {
    position: relative;
    margin-top: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .footer .footer-newsletter .newsletter-form .button .btn {
    width: 100%;
  }
}

.footer .footer-newsletter .newsletter-form .button .btn {
  display: inline-block;
  background: #ed1d8d;
  color: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
}

.footer .footer-newsletter .newsletter-form .button .btn::before {
  background-color: #fff;
}

.footer .footer-newsletter .newsletter-form .button .btn:hover {
  color: #ed1d8d;
  background-color: #fff;
}

</style>