<template>
    <Header/>
    <Herosection/>
    <Feature/>
    <Calltoaction/>
    <FeatureArea/>
    <Product/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue';
import Herosection from '@/components/herosection.vue'
import Feature from '@/components/feature.vue';
import FeatureArea from '@/components/featurearea.vue';
import Footer from '@/components/footer.vue';
import Calltoaction from '@/components/calltoaction.vue';
import Product from '@/components/productsection.vue';

export default {
  components: {
    Herosection,
    Header,
    Feature,
    FeatureArea,
    Footer,
    Calltoaction,
    Product
  }
}
</script>
<style >

</style>
